import {
  assert,
  hasValue,
  isArray,
  isTrue,
  isNotBlank,
  split
} from 'ajax-form-submit/js-utils'

import {
  querySelector,
  isElement,
  elementIs,
  hasClass,
  addClass,
  getTargets,
  removeClass,
  registerEvent,
  triggerEvent,
  registerMutationObserver,
  stopDefaultEvent
} from 'ajax-form-submit/js-dom-utils'

import { createDatasetHelper } from 'ajax-form-submit/js-dataset-helper'

const instanceMap = new Map()
const TOGGLE_SELF_ATTRS = ['aria-expanded']
const ATTR_DEFAULT_VALUE = { false: false, true: true }
const POINTER_EVENT = ['click', 'change']

const CLASS_NAME = 'toggle'
const INIT_CLASS_NAME = `${CLASS_NAME}-initialized`
const INSIDE_CLASS_NAME = `${CLASS_NAME}-inside`
const OUTSIDE_CLASS_NAME = `${CLASS_NAME}-outside`

class Toggle {

  static prefix = 'tg'

  static create(el) {
    if (hasClass(el, INIT_CLASS_NAME))
      return

    const instance = Toggle.getInstance(el)
    if (!instance)
      instanceMap.set(el, new Toggle(el))
    return Toggle.getInstance(el)
  }

  static getInstance(el) {
    assert(isElement(el), 1, 'HTMLElement')
    return instanceMap.get(el)
  }

  constructor(el, opt = {}) {
    assert(isElement(el), 1, 'HTMLElement')

    this._datasetHelper = createDatasetHelper(Toggle.prefix)
    this._root = el
    this._showTargets = getTargets(this._datasetHelper.getValue(el, 'show'), el)
    this._hideTargets = getTargets(this._datasetHelper.getValue(el, 'hide'), el)
    this._dismissTargets = getTargets(this._datasetHelper.getValue(el, 'dismiss'), el)
    this._toggleTargets = getTargets(this._datasetHelper.getValue(el, 'toggle'), el)
    this._focusTargets = getTargets(this._datasetHelper.getValue(el, 'focus'), el)
    this._outsideClick = el.hasAttribute(this._datasetHelper.keyToAttrName('outside-click'))

    const trigger = this._datasetHelper.getValue(el, 'trigger') || 'click'
    const open = isTrue(this._datasetHelper.getValue(el, 'open'))
      || TOGGLE_SELF_ATTRS.reduce((acc, attr) => acc || isTrue(el.getAttribute(attr)), false)

    if (POINTER_EVENT.includes(trigger))
      el.style.cursor = 'pointer'

    switch(trigger) {
      case 'click':
        this._handleClick()
        break
      case 'change':
        this._handleChange()
        triggerEvent(this._root, 'change')
        break
      default:
        this._handleDefault(trigger)
    }

    if (open) {
      this._run()
      TOGGLE_SELF_ATTRS.forEach(attrName => {
        if (!this._root.hasAttribute(attrName))
          return
        const value = isTrue(this._root.getAttribute(attrName))
        this._root.setAttribute(attrName, !value)
      })
    }
  }

  _handleDefault(trigger) {
    registerEvent(this._root, trigger, event => {
      stopDefaultEvent(event)
      this._run()
    })
  }

  _handleClick() {
    const outsideClick = ({ target }) => {
      if (!this._open)
        return

      const insideElems = [this._root, ...this._toggleTargets]
      let isInside = hasClass(target, INSIDE_CLASS_NAME)
        || (!hasClass(target, OUTSIDE_CLASS_NAME) && insideElems.reduce((acc, elem) => acc || elem.contains(target), false))
      if (!isInside) {
        this._toggleSelf()
        this._toggle(this._toggleTargets)
        document.removeEventListener('click', outsideClick)
      }
    }

    registerEvent(this._root, 'click', event => {
      this._run()
      this._outsideClick && document.addEventListener('click', outsideClick, true)
    })
  }

  _handleChange() {
    if (elementIs(this._root, 'select')) {
      const attrName = this._datasetHelper.keyToAttrName('target')
      const targets = {}
      querySelector('option', this._root)
        .map(el => el.getAttribute(attrName))
        .filter(str => isNotBlank(str))
        .forEach(selector => targets[selector] = querySelector(selector))

        registerEvent(this._root, 'change', event => {
          stopDefaultEvent(event)
          const selectedOptions = Array.from(event.target.selectedOptions)
            .map(opt => this._datasetHelper.getValue(opt, 'target'))
            .map(selector => targets[selector])
            .filter(hasValue)
          Object.values(targets).forEach(el => this._toggle(el, false))
          selectedOptions.forEach(el => this._toggle(el, true))
        })

    } else {
      registerEvent(this._root, 'change', event => {
        stopDefaultEvent(event)
        this._run()
      })
    }
  }

  _dismiss(targets) {
    assert(isArray(targets), 1, 'Array')
    for (const el of targets) {
      isElement(el) && el.remove()
    }
  }

  _toggle(targets, flag = this._open) {
    assert(isArray(targets), 1, 'Array')

    for (const el of targets) {
      const lockScreen = el.hasAttribute(this._datasetHelper.keyToAttrName('lock-screen'))

      if (lockScreen)
        document.documentElement.style.overflow = flag ? 'hidden' : null

      getToken(this._datasetHelper.getValue(el, 'class')).forEach(({ isNegative, name }) => {
        isTrue(isNegative ^ flag) ? addClass(el, name) : removeClass(el, name)
      })

      getToken(this._datasetHelper.getValue(el, 'attr')).forEach(({ isNegative, name }) => {
        const valueSetting = {
          true: this._datasetHelper.getValue(this._root, `attr-${name}-true`)
            || this._datasetHelper.getValue(el, `attr-${name}-true`),
          false: this._datasetHelper.getValue(el, `attr-${name}-false`)
        }

        const key = isTrue(isNegative ^ flag).toString()
        const value = valueSetting[key] || ATTR_DEFAULT_VALUE[key]
        value ? el.setAttribute(name, value) : el.removeAttribute(name)
      })
    }
  }

  _focus(target) {
    isElement(target) && target.focus()
  }

  _toggleSelf() {
    this._open = !this._open
    TOGGLE_SELF_ATTRS.forEach(attrName => {
      if (!this._root.hasAttribute(attrName))
        return
      const value = isTrue(this._root.getAttribute(attrName))
      this._root.setAttribute(attrName, !value)
    })
  }

  _run() {
    this._toggleSelf()
    this._toggle(this._showTargets, true)
    this._toggle(this._hideTargets, false)
    this._dismiss(this._dismissTargets)
    this._toggle(this._toggleTargets)
    this._focus(this._focusTargets[0])
  }
}

function getToken(prop) {
  return split(prop).map(str => {
    const firstChar = str.charAt(0)
    const isNegative = firstChar === '-'
    return {
      isNegative,
      name: isNegative ? str.substring(1, str.length) : str
    }
  })
}

export default Toggle
window && (window.Toggle = Toggle)

window.addEventListener('DOMContentLoaded', event => {
  const datasetHelper = createDatasetHelper(Toggle.prefix)
  const toggleAttr = datasetHelper.keyToAttrName('toggle')
  const showAttr = datasetHelper.keyToAttrName('show')
  const hideAttr = datasetHelper.keyToAttrName('hide')
  const dismissAttr = datasetHelper.keyToAttrName('dismiss')
  const focusAttr = datasetHelper.keyToAttrName('focus')
  const selector = `[${toggleAttr}],[${showAttr}],[${hideAttr}],[${dismissAttr}],[${focusAttr}]`
  registerMutationObserver(el => {
    if (!isElement(el))
      return

    querySelector(selector, el, true).forEach(el => Toggle.create(el))

    if (el.hasAttribute(toggleAttr)
      || el.hasAttribute(showAttr)
      || el.hasAttribute(hideAttr)
      || el.hasAttribute(dismissAttr)
      || el.hasAttribute(focusAttr))
      Toggle.create(el)
  })

  querySelector(selector).forEach(el => Toggle.create(el))
}, { once: true })
